/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
// @ngInject
function states($stateProvider) {
  $stateProvider.state('move-money.check-deposit', {
    url: '/check-deposit',
    templateUrl: 'apps/check-deposit/templates/acp-check-deposit.ng.html',
    controller: 'acpCheckDepositSectionCtrl',
    resolve: {
      module: /* @ngInject */ function($ocLazyLoad) {
        return import(/* webpackChunkName: "acp.apps.check-deposit" */ 'apps/check-deposit').then(
          $ocLazyLoad.loadModule
        );
      }
    }
  });
}

export default states;
